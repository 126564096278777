import React, { useContext, useState } from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { StaticImage } from 'gatsby-plugin-image';
import ContentBlock from 'components/content-block';

import Bolt from 'components/bolts';
import { VideoModal } from 'components/video-modal';

import { appContext } from 'providers/appProvider';

import sprayBottle from 'images/patient/pages/about-sprix/about-sprix-spraybottle.png';
import painMan from 'images/patient/pages/about-sprix/about-sprix-shoulder-pain.png';
import checkmark from 'images/patient/pages/about-sprix/about-sprix-checkmark.png';
import calendar from 'images/patient/pages/about-sprix/about-sprix-calendar.png';
import calendarCheckmark from 'images/patient/pages/about-sprix/about-sprix-calendar-with-checkmark.png';

import bottleIcon from 'images/patient/pages/about-sprix/about-sprix-bottle-icons.png';

import howToUse1 from 'images/patient/pages/about-sprix/about-sprix-how-to-use-1.png';
import howToUse2 from 'images/patient/pages/about-sprix/about-sprix-how-to-use-2.png';
import howToUse3 from 'images/patient/pages/about-sprix/about-sprix-how-to-use-3.png';
import howToUse4 from 'images/patient/pages/about-sprix/about-sprix-how-to-use-4.png';
import videoPoster from 'images/patient/pages/about-sprix/about-sprix-video-poster.png';
// import page style
import './about-sprix.scss';

const pageTitle = 'About SPRIX';
const pageDescription = '';

const Dosing = () => {
  return (
    <div id="dosing" className="gray">
      <ContentBlock>
        <Row>
          <Col xs={12}>
            <h2 className="spray-headline">
              <strong>SPRIX® (ketorolac tromethamine)</strong> comes in a carton
              with <strong>5</strong> pre-filled bottles
            </h2>
          </Col>
        </Row>
        <Row center="xs" className="bottle-icon-container">
          <Col xs={10}>
            <img className="bottle-icon" src={bottleIcon}></img>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="spray-copy">
              <strong>
                SPRIX is delivered through a nasal spray. Each carton contains 5
                bottles, with each bottle containing 8 precisely metered doses.
              </strong>
            </p>
            <p className="spray-small-copy">SPRIX is intended for use:</p>
          </Col>
        </Row>
        <Row className="spacing-left">
          <Col xs={12} md={3}>
            <p className="spray-small-copy">
              <span>•</span> Every 6-8 hours*
            </p>
          </Col>
          <Col xs={12} md={4}>
            <p className="spray-small-copy">
              <span>•</span> Up to 4 times a day
            </p>
          </Col>
          <Col xs={12} md={4}>
            <p className="spray-small-copy">
              <span>•</span> For up to 5 days, as needed
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="spray-small-copy">
              <span>*</span>For adult patients &lt; 65 years of age,{' '}
              <br className="display-only-mobile" />≥ 110 lbs, and normal kidney
              function, the recommended dose is 1 spray in each nostril every
              6-8 hours up to 4 times a day. For adult patients ≥ 65 years of
              age, renally impaired patients, and adult patients &lt; 110 lbs,
              the recommended dose is 1 spray in 1 nostril every 6-8 hours up to
              4 times per day.
            </p>
            <p className="how-to-store-copy">
              <strong>How should I store SPRIX?</strong>
            </p>
            <ul className="large-list">
              <li>
                Store unopened SPRIX bottles between 36°F to 46°F (2°C to 8°C)
              </li>
              <li>Keep opened bottles of SPRIX at room temperature</li>
              <li>Keep SPRIX out of direct sunlight</li>
              <li>Do not freeze SPRIX</li>
              <li>
                SPRIX does not contain a preservative. Throw away each SPRIX
                bottle within 24 hours of taking your first dose, even if the
                bottle still contains unused medicine
              </li>
            </ul>
          </Col>
        </Row>
      </ContentBlock>
    </div>
  );
};

const HowToUse = () => (
  <div id="how-to-use" className="dark-blue">
    <ContentBlock>
      <Row>
        <Col xs={12}>
          <h2 className="white">
            <strong>Instructions for use</strong> of sprix® (ketorolac
            tromethamine) nasal spray
          </h2>
          <p className="instructions-sub-headline">
            SPRIX is for use in the nose only. Do not breathe in (inhale) SPRIX.
          </p>
        </Col>
      </Row>
      <Row>
        <Col col={12} lg={3} mr={1}>
          <img src={howToUse1} />
        </Col>
        <Col col={12} lg={8}>
          <p className="how-to-use-copy">
            Before you use SPRIX for the first time, you will need to prime the
            bottle. Hold the finger flange with your fingers (
            <strong>see Figure A</strong>), and remove the clear plastic cover
            with your opposite hand. Keep the clear plastic cover for later.
            Remove and throw away the blue plastic safety clip.
          </p>
        </Col>
      </Row>
      <Row pt={2} wrap px={2}>
        <Col col={12} lg={3} mr={1}>
          <img src={howToUse2} />
        </Col>
        <Col col={12} lg={8}>
          <p className="how-to-use-copy">
            If the clear plastic cover is improperly removed, the tip of the
            bottle may be pulled off of the glass vial. If this happens, place
            the tip back onto the glass vial by lining it up carefully and
            gently pushing it back on until it is back in the correct position (
            <strong>see Figure B</strong>). The SPRIX bottle should work
            properly again.
          </p>
        </Col>
      </Row>
      <Row pt={2} wrap px={2}>
        <Col col={12} lg={3} mr={1}>
          <img src={howToUse3} />
        </Col>
        <Col col={12} lg={8}>
          <p className="how-to-use-copy">
            Hold the SPRIX bottle upright at arm’s length away from you with
            your index finger on top of the finger flange and your thumb
            supporting the base (<strong>see Figure C</strong>).
          </p>
          <p className="how-to-use-copy">
            <strong>
              Press down on the finger flange and release the pump 5 times.
            </strong>{' '}
            You may not see spray the first few times you press down. Now the
            pump is primed and ready to use. You do not need to prime the pump
            again if you use more doses from this bottle.
          </p>
        </Col>
      </Row>
      <Row className="standard-top-spacing">
        <Col col={12} lg={3} mr={1}>
          <img src={howToUse4} />
        </Col>
        <Col col={12} lg={8}>
          <p className="how-to-use-copy">
            Blow your nose to clear your nostrils. Sit up straight or stand.
            Keep your head tilted downward toward your toes. Place the tip of
            the SPRIX bottle into your right nostril. Hold the SPRIX bottle
            upright and aim the tip toward the back of your nose (
            <strong>see Figure D</strong>).
          </p>
          <p className="how-to-use-copy">
            Hold your breath and spray 1 time into your right nostril, pressing
            down on both sides of the finger flange (
            <strong>see Figure D</strong>). Breathe in gently through your mouth
            after you use SPRIX. You may also pinch your nose to help keep the
            medicine in your nose.
          </p>
        </Col>
      </Row>
      <Row px={2}>
        <Col>
          <p className="how-to-use-copy">
            <strong>
              SPRIX is for use in the nose only. Do not breathe in (inhale)
              SPRIX.
            </strong>
          </p>
          <p className="how-to-use-copy">
            If your healthcare provider has prescribed only 1 spray per dose for
            you, you have now finished your dose. However, if your healthcare
            provider has prescribed 2 sprays for you, repeat the above steps for
            your left nostril. Be sure to point the spray away from the center
            of your nose. Spray 1 time into your left nostril. When you are
            finished using SPRIX, put the clear plastic cover back on the SPRIX
            bottle.
          </p>
        </Col>
      </Row>
    </ContentBlock>
  </div>
);

const Video = ({ handleClick }) => (
  <div className="blue">
    <ContentBlock classes="video-container">
      <Row middle="xs">
        <Col xs={12} md={6}>
          <p className="video-copy">
            SPRIX<sup>&reg;</sup> Patient Use Video
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img
            src={videoPoster}
            alt="how to use"
            onClick={e =>
              handleClick('https://player.vimeo.com/video/222721740', e)
            }
          />
        </Col>
      </Row>
    </ContentBlock>
  </div>
);

const AboutPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  const handleClick = (url, e) => {
    e.persist();
    setSelectedVideoUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setSelectedVideoUrl('');
    setShowModal(false);
  };

  return (
    <Layout
      indication={siteIndications.patient}
      className="patient-about-sprix"
    >
      <Seo pageTitle="Patient About Page" pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <h2 id="what-is-sprix" className="blue-headline align-left">
              <strong>SPRIX® (ketorolac tromethamine)</strong> is:
            </h2>
            <div className="about-sprix-top-container">
              <Row>
                <Col xs={12} md={8}>
                  <ul className="large-list">
                    <li>
                      Prescribed for adult patients who require opioid-strength
                      pain relief for up to 5 days
                    </li>
                    <li>
                      The first nonsteroidal anti-inflammatory drug (NSAID)
                      pain-relieving nasal spray
                    </li>
                    <li>A medicine that contains a potent pain reliever</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img src={sprayBottle}></img>
                </Col>
              </Row>
              <div>
                <Row middle="md">
                  <Col xs={12} md={4}>
                    <Bolt color="blue" classes="pain-guy-bolts" />
                    <img className="pain-man" src={painMan}></img>
                  </Col>
                  <Col xs={11} md={6}>
                    <h2 className="blue-headline text-left">
                      <strong>SPRIX</strong> is not:{' '}
                    </h2>
                    <ul className="large-list yellow-list">
                      <li>An opioid narcotic pill</li>
                      <li>A patch or injection</li>
                      <li>A controlled substance </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <Row middle="md">
                <Col xs={12} md={8} className="calendar-text">
                  <p className="large-copy-block">
                    SPRIX Nasal Spray can be used for short-term treatment of
                    moderate to severe pain for up to 5 days in adult patients
                    who require opioid-strength pain relief.
                  </p>
                </Col>
                <Col xs={12} md={4} className="calendar-checkmark">
                  <img className="calendar" src={calendarCheckmark} />
                  {/* <StaticImage
                    src="../../images/patient/pages/about-sprix/about-sprix-calendar-with-checkmark.png"
                    placeholder="none"
                    alt="Calendar with checkmark"
                    className="calendar"
                  /> */}
                </Col>
              </Row>
            </div>
          </ContentBlock>
          <Dosing />
          <HowToUse />
          <Video handleClick={handleClick} />
          <VideoModal
            closeModal={handleClose}
            open={showModal}
            url={selectedVideoUrl}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
